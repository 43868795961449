<!--
 * @Author: Jeff.Guo
 * @Description: 档案号输入组件
 * @result XXXX000000000
-->
<template>
    <section ref="file_number_wrap">
        <el-input
            v-model="params.file_number1"
            maxlength="4"
            style="width: 83px"
            @keyup.native="params.file_number1 = params.file_number1.replace(/[^A-Z]/g,'')"
        /> -
        <el-input
            ref="file_number2"
            v-model="params.file_number2"
            maxlength="7"
            style="width: 86px"
            @keyup.native="params.file_number2 = params.file_number2.replace(/[^\d]/g,'');"
        /> -
        <el-input
            ref="file_number3"
            v-model="params.file_number3"
            maxlength="2"
            style="width: 60px"
            @keyup.native="params.file_number3 = params.file_number3.replace(/[^\d]/g,'');"
        />
    </section>
</template>

<script>
export default {
    components: {},
    props: {
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        default: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            params: {
                file_number1: "QMEN",
                file_number2: "",
                file_number3: "",
            },
        }
    },
    watch: {
        params: {
            deep: true,
            handler (newVal) {
                // 拼起来传出去
                const { file_number1, file_number2, file_number3 } = this.params
                if ((file_number1 && file_number2 && file_number3)) {
                    this.$emit("input", Object.values(this.params).join("-"))
                } else {
                    this.$emit("input", null)
                }
                // 输完切换焦点
                if (
                    document.activeElement.maxLength == 7 &&
                    newVal.file_number2.length >= 7
                )
                    this.$refs.file_number3.focus()
            },
        },
        value: {
            handler(newVal) {
                if (newVal) {
                    let keepNum = newVal.split("-")
                    this.params = {
                        file_number1: keepNum[0],
                        file_number2: keepNum[1],
                        file_number3: keepNum[2],
                    }
                }
            },
            immediate: true, // 可选，如果想要立即执行处理函数
        }
    },
    methods: {
        change(e) {
            this.params.file_number1 = e.target.value
        },
    },
};
</script>

<style lang="less" scoped>
.el-select-dropdown__item/deep/ {
    height: 166px;
    padding: 10px 20px;
    border-bottom: 1px solid #d1d1d1;

    &:hover,
    &.hover {
        background: #c8e2fd;
    }

    p {
        width: 350px;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>
