<!--
 * @Author: filename
 * @Description: 申请人信息
-->
<template>
    <div>
        <div class="card-container">
            <div class="card-heard">主申请人信息</div>
            <div v-loading="isLoading" class="form-box">
                <el-form
                    ref="userForm"
                    :model="userForm"
                    :rules="rules"
                    label-width="auto"
                    label-position="left"
                >
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="主申姓名：" prop="username">
                                <el-input v-model.trim="userForm.username" clearable maxlength="20" placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="性别：" prop="sex">
                                <el-select v-model="userForm.sex" placeholder="请选择" style="width: 100%;" clearable>
                                    <el-option label="男" :value="1" />
                                    <el-option label="女" :value="2" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="出生日期：" prop="birthday">
                                <el-date-picker
                                    v-model="userForm.birthday"
                                    style="width: 100%;"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    :picker-options="pickerOptions"
                                    placeholder="请选择"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="手机号码：" prop="mobile">
                                <el-input v-model.trim="userForm.mobile" clearable placeholder="请输入" type="number" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="档案号：" prop="fileNumber">
                                <div style="display:flex;">
                                    <FileNumberInput v-model="userForm.fileNumber" />
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="港澳通行证号码：">
                                <el-input v-model.trim="userForm.passNumber" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="护照号码：">
                                <el-input v-model.trim="userForm.identityCard" clearable placeholder="请输入" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国籍：" prop="nationality">
                                <CountrySelect v-model="userForm.nationality" style="width: 100%;" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="婚姻状况：" prop="married">
                                <el-select v-model="userForm.married" style="width: 100%;" placeholder="请选择" clearable>
                                    <el-option label="已婚" value="1" />
                                    <el-option label="未婚" value="2" />
                                    <el-option label="离婚" value="3" />
                                    <el-option label="分居" value="4" />
                                    <el-option label="丧偶" value="5" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="现居地地址：" prop="address">
                                <CurrentAddress ref="CurrentAddress" v-model="userForm.address" country-placeholder="请输入匹配国家" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>
        <div class="operations-group">
            <el-button v-if="(user.baseSchedule - 1) < 1" class="person-btn" :loading="isLoading" @click="handleSave">保存</el-button>
            <el-button class="person-btn btn-blue" @click="saveForm('userForm')">下一步</el-button>
        </div>
    </div>
</template>

<script lang="javascript">
import CountrySelect from "@/components/CountrySelect/CountrySelect"
import FileNumberInput from "@/components/FileNumberInput/FileNumberInput"
import CurrentAddress from "@/components/CurrentAddress/CurrentAddress"
import { smallInformationSave, getInformationDetail, smallInformationSubmit } from "@/api/workfow.js"
import { mapActions, mapState } from "vuex"
export default {
    name: "SmallUser",
    components: {
        CountrySelect,
        FileNumberInput,
        CurrentAddress,
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            isLoading: false,
            userForm: {
                id: '',
                // 订单id
                orderId: this.$route.params.id,
                // 现居住地
                address: {
                    area: [],
                    foreign: '',
                    details: '',
                    country: ''
                },
                // 出生日期
                birthday: '',
                // 国籍
                nationality: '',
                // 婚姻
                married: '',
                // 性别
                sex: '',
                // 港澳通行证号
                passNumber: '',
                // 护照号
                identityCard: '',
                // 主申请人姓名
                username: '',
                // 档案号
                fileNumber: '',
                // 手机号
                mobile: ''
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入主申姓名",
                        trigger: "change",
                    },
                ],
                fileNumber: [
                    {
                        validator: this.$rules.validate.fileNum,
                        trigger: "change",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                birthday: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "change",
                    },
                ],
                nationality: [
                    {
                        required: true,
                        message: "请输入国籍",
                        trigger: "change",
                    },
                ],
                married: [
                    {
                        required: true,
                        message: "请选择婚姻状况",
                        trigger: "change",
                    },
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                ],
            },
        }
    },
    computed: {
        ...mapState(["user"])
    },
    created() {
        this.getDetail()
    },
    methods: {
        ...mapActions(["getInfoData"]),
        // 获取详情
        async getDetail() {
            try {
                this.isLoading = true
                const { code, data } = await getInformationDetail({ orderId: this.$route.params.id })
                if (code === 200) {
                    if (data.information) {
                        for (const key in this.userForm) {
                            if (data.information[key]) {
                                this.userForm[key] = data.information[key]
                            }
                        }
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 保存
        async handleSave() {
            try {
                localStorage.setItem("stepChange", 0)
                this.$store.commit("stepChange")
                this.isLoading = true
                const { code } = await smallInformationSave(this.userForm)
                if (code === 200) {
                    this.$message.success("保存成功！");
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 下一步保存
        saveForm(formData) {
            localStorage.setItem("stepChange", 0)
            this.$store.commit("stepChange")
            this.$refs["userForm"].validate(async(valid) => {
                if (valid) {
                    try {
                        this.isLoading = true
                        const { code } = await smallInformationSubmit(this.userForm)
                        if (code === 200) {
                            this.$message.success("提交成功");
                                // 切换步骤保存 下一步的时候才设置
                                this.saveUserData(2)
                                setTimeout(() => {
                                    this.$router.replace({
                                        name: "SmallStep2",
                                        query: {
                                            id: this.$route.query.id
                                        }
                                    })
                                }, 500)
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.isLoading = false
                    }

                } else {
                    return false
                }
            })
        },
    },
};
</script>

<style lang="less" scoped>
.card-container {
    width: 960px;
    margin: 0 auto;
    .card-heard {
        height: 35px;
        line-height: 35px;
        background: #409eff;
        padding-left: 16px;
        border-radius: 4px 4px 0px 0px;
        color: #fff;
    }
}

.operations-group {
    width: 960px;
    margin: 25px auto 50px;
    display: flex;
    justify-content: flex-end;
}

.form-box {
    padding: 20px;
}
</style>
