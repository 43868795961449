<!--
 * @Author: filename
 * @Description: 现居住地址
-->
<template>
    <el-form ref="ruleForm" :model="value" :rules="rules" class="current-address">
        <el-form-item prop="country">
            <CountrySelect v-model="value.country" :placeholder="countryPlaceholder" />
        </el-form-item>
        <el-form-item v-if="value.country && value.country.includes('中国')" prop="area">
            <CitySelect v-model="value.area" :country="value.country" />
        </el-form-item>
        <!-- 国内地址 -->
        <el-form-item v-if="value.country && value.country.includes('中国')" prop="details">
            <el-input v-model="value.details" clearable auto-complete="off" maxlength="50" :placeholder="placeholder">
                <template v-if="value.area && value.area.length" slot="prepend">{{ value.area.join('') }}</template>
            </el-input>
        </el-form-item>
        <!-- 国外地址 -->
        <el-form-item v-if="value.country && !value.country.includes('中国')" prop="foreign">
            <el-input v-model="value.foreign" clearable auto-complete="off" maxlength="50" :placeholder="placeholder" />
        </el-form-item>
    </el-form>
</template>

<script>
import CountrySelect from "@/components/CountrySelect/CountrySelect"
import CitySelect from "@/components/CitySelect/CitySelect"
export default {
    name: 'CurrentAddress',
    components: {
        CountrySelect,
        CitySelect,
    },
    props: {
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        placeholder: {
            type: String,
            default: "请输入"
        },
        countryPlaceholder: {
            type: String,
            default: "请输入匹配国籍"
        }
    },
    data() {
        return {
            checked1: 0,
            rules: {
                country: [{ required: true, message: "请选择国家", trigger: "change", }],
                area: [{ required: true, message: "请选择城市", trigger: "change", }],
                details: [{ required: true, message: "请输入地址", trigger: "blur", }],
                foreign: [{ required: true, message: "请输入地址", trigger: "blur", }],
            }
        }
    },
    methods: {
        // 校验表单
        checkInfo() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async(resolve, reject) => {
                try {
                    await this.$refs.ruleForm.validate()
                    if (this.value.country && !this.value.country.includes('中国')) {
                        this.is_live_oversea ? resolve() : reject('error')
                    } else {
                        resolve()
                    }
                } catch (error) {
                    reject(error)
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.current-address {
    display: flex;
    >div:not(:last-of-type) {
        margin-right: 10px;
    }
    .el-select {
        width: 200px;
    }
    >div:nth-of-type(3) {
        flex: 1;
    }
    .el-form-item {
        margin-bottom: 0;
    }
}
    #live_oversea {
        position: relative;

        &_warning {
            position: absolute;
            left: 150px;
            bottom: -20px;
            color: red;
            font-size: 12px;
        }
    }
</style>
