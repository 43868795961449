<!--
 * @Author: filename
 * @Description: 国籍选择组件
 * @example <CountrySelect v-model="nationality" />
-->
<template>
    <el-select
        v-model="nationality"
        clearable
        filterable
        remote
        reserve-keyword
        :placeholder="placeholder"
        :remote-method="queryCountryAsync"
        :loading="isLoading"
        @change="handleSelect"
    >
        <el-option v-for="item in countryOption" :key="item.name_en" :label="item.name_cn + '-' + item.name_en" :value="item.name_cn + '-' + item.name_en" />
    </el-select>
</template>

<script>
import { querySearchCountry } from "@/api/workfow.js"
export default {
    name: 'CountrySelect',
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '请输入匹配国籍',
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isLoading: false,
            nationality: '',
            countryOption: [], // 国家选项
        }
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal) {
                    this.initValue(newVal)
                }
            },
            immediate: true, // 可选，如果想要立即执行处理函数
        }
    },
    methods: {
        // 初始化
        initValue(val) {
            this.nationality = val
            if (this.countryOption.length === 0) {
                this.countryOption.push({
                    name_cn: val.split('-')[0],
                    name_en: val.slice(val.indexOf('-') + 1),
                })
            }
        },
        // 获取国籍
        async queryCountryAsync(keyword) {
            try {
                this.isLoading = true
                const res = await querySearchCountry({ keyword })
                if(res.code === 200) {
                    this.countryOption = res.data
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        handleSelect(v) {
            this.$emit("input", v, this.index)
        },
    },
}
</script>

<style scoped lang="less">

</style>
